.update__dialog {
    &-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
    }

    &-content {
        padding: 10px;

        @include tablet {
            padding: 25px;
            overflow: auto;
        }
    }
}

.event__update-form {    

    .event__item {
        padding: 5px 0;
    }

    .event__button {
        margin: 15px 0; 
    }

    .event__update-actions {
        float: right;
    }

    .event__update-photo {
        #photo_update {
            display: none;
        }

        img {
            max-height: 200px;
            width: 100%;

            @include tablet {
                width: auto;
            }
        }

        .photo_info {
            color: $grey500;
            padding: 5px 0;
        }

        .photo_error {
            color: $error;
            padding: 5px 0;
        }
    }

    .remove_photo {
        color: $error;
        margin-left: 16px !important;
    }
}