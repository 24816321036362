.profil__private {

    @include tablet {
        margin: 0 auto;
        padding: 50px 20px;
        max-width: $profil-width;
        min-height: $page-content-height;
    }

    &-components {
        min-height: 200px;
    }
    
    &-grid {
        padding: 10px;
    }

    &-info {
        text-align: center;


        img {
            min-height: 180px;
            max-height: 214px;
            width: 180px;
            border-radius: 4px;
        }

        .info-text {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .info-name {
            margin: 0;
            padding: 5px 0;
            font-weight: 500;
        }
    }

    &-actions {
        flex-grow: 1;
    }

    &-nav {
        display: flex;
        justify-content: space-around;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        @include tablet {
            border: none;
            justify-content: left;
            padding-left: 15px;

            a {
                border-top: 1px solid $border-color;
                color: inherit;
                padding: 15px;
                
    
                &:hover {
                    border-top-color: $secondary-color;
                    color: $secondary-color;
                    text-decoration: none;
                }
            }
        }   

        .active-link {
            color: $secondary-color;
            
            @include tablet {
                border-top-color: $secondary-color;
            }
        }
    }    
}

.profil__public {
    min-height: calc(100vh - 260px);
    
    @include tablet {
        margin: 0 auto;
        padding: 50px 20px;
        max-width: $profil-width;
        min-height: $page-content-height;
    }

    &-grid {
        min-height: 140px;
        padding: 10px;
    }

    &-info {
        text-align: center;


        img {
            min-height: 180px;
            max-height: 214px;
            width: 180px;
            border-radius: 4px;
        }        

        .info-name {
            margin: 0;
            padding: 5px 0;
            font-weight: 500;
        }

        .request__send {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                margin: 5px;
                width: 100%;
            }
        }

        .add__friend {
            display: flex;
            justify-content: center;
            padding-top: 5px;
        }

        .request__waiting {
            display: flex;
            justify-content: center;
            color: $secondary-color;
            padding-top: 5px;

            h3 {
                margin: 0;
            }
        }

        .friends {
            color: $official-color;
            padding-top: 5px;
            display: flex;
            align-items: center;
            flex-direction: column;

            h3 {
                margin: 0;
                padding-bottom: 10px;
            }

            .unfriend-button {
                color: $grey500;
                @include font-size(12);
            }

            &__placeholder {
                background: $grey200;
                border-radius: 4px;
                height: 25px;
                width: 110px;
                margin: 0 auto;
            }
            
        }
    }

}