.notifications-page {
    padding: 15px 10px;
    text-align: center;
    min-height: $page-content-height;

    @include tablet {
        padding: 60px 0;
    }

    &__container {
        max-width: $container-width;    
        margin: 0 auto;

        @include tablet {
            box-shadow: 0 0 5px #ddd;
            padding: 30px;
        }
    }

    &__list {
        .list-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #8d8d8d;
            padding: 8px;
    
            &:last-child{
                border-bottom: none;
                justify-content: center;
            }
    
            .not-name {
                @include font-size(15);
                color: $official-color;
    
                &:hover {
                    color: $official-color;
                    text-decoration: underline;
                    text-decoration-color: $official-color;
                }
            }      
            
            .not-time {
                @include font-size(13);
                color: $grey500;
                padding-right: 8px;
                text-align: right;
            }

            &-avatar {
                margin-right: 15px;
            }

            &-text {
                width: 100%;

                @include tablet {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .delete-btn {
            color: $error;
        }
    }

    &__title {
        margin: 0;
        text-align: center;
        padding: 10px 0;
    }

    &__friends {
        text-align: left;
        padding-bottom: 10px;
    }

    &__placeholder {
        padding-top: 5px;
        padding-left: 10px;

        @include tablet {
            padding-left: 15px;
        }

        .placeholder-wrap {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
        }

        .placeholder-photo {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $grey300;
        }

        .placeholder-text {
            display: inline-block;
            width: 120px;
            height: 25px;
            background-color: $grey300;
            border-radius: 4px;
            margin-left: 10px;
        }
    }
}

.friend-notification-list,
.notifications-list {
    position: absolute;
    background-color: #fff;
    border: 1px solid #bdbdbd;
    box-shadow: 1px 1px 3px #bdbdbd;
    color: $official-color;
    right: 0;    
    top: 57px;
    min-width: 460px;
    z-index: 1200;      
    
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
    }

    &::before {
        right: 11px;
        border: 11px solid transparent;
        border-bottom-color: #bdbdbd;
    }

    &::after {
        right: 11px;
        border: 10px solid transparent;
        border-bottom-color: #fff;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    .list-item {
        border-bottom: 1px solid #8d8d8d;
        padding: 8px;

        &:last-child{
            border-bottom: none;
            justify-content: center;
        }

        .not-name {
            @include font-size(15);
            color: $official-color;

            &:hover {
                color: $official-color;
                text-decoration: underline;
                text-decoration-color: $official-color;
            }
        }

        .not-time {
            @include font-size(13);
            color: $grey500;
            padding-right: 8px;
            text-align: right;
        }

        .show-all {
            color: $secondary-color;
            text-align: center;

            &:hover {
                color: $secondary-color;
                text-decoration: underline;
            }
        }
    }   

    .reject-button {
        color: $error !important;
    }

    .detail-button {
        color: $secondary-color !important;
    }
}

//notifications dialog

.notifications-dialog {
    
    &-toolbar {
        display: flex;
        justify-content: space-between;
    }

    &-title {
        margin: 0;

        a {
            color: $third-color;

            &:hover {
                text-decoration: underline;
                text-decoration-color: $third-color;
            }
        }
    }
    
    &-wrapper {
        @include tablet {
            max-width: $container-width;
            margin: 0 auto;
            box-shadow: 0 0 5px #ddd;
            padding: 30px;
        }

        &--media {
            text-align: center;
        }
        &--photo {
            padding: 10px;
            img {
                border-radius: 50%;
                width: 150px;
                height: 150px;

                @include tablet {
                    width: 200px;
                    height: 200px;
                }
            }
        }

        &--actions {
            display: flex;
            justify-content: center;
            
            &-reject {
                background-color: $error !important;
                color: #fff !important;
                margin-left: 16px !important;
            }
        }

        &--info {
            text-align: left;
            @include font-size(14);

            @include tablet {
                @include font-size(16);
                text-align: center;
                padding: 20px;
            }

            a {
                color: $secondary-color;

                &:hover {
                    text-decoration: underline;
                    text-decoration-color: $secondary-color;
                }
            }

            &-contact {
                margin: 0;
            }

            &-provider,
            &-message {
                font-weight: 500;
            }
        }
    }
}

.notifications-dialog .notifications-dialog-cont {
    @include tablet {
        padding: 60px 20px;
    }
}

/**
* MOBILE DIALOG
*/

.notification__dialog .notification__dialog-content {
    padding: 0;
}