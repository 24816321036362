.users__sports {

    &-wrapper {
        @include tablet {
            height: $content-height;
            overflow: auto;
        }  
    }

    &-nav {
        text-align: center;

        &--link {
            border-bottom: 2px solid $border-color;
            color: inherit;
            display: inline-block;
            padding: 15px;
            width: 50%;

            &:hover {
                border-bottom-color: $official-color;
                color: $official-color;
                text-decoration: none;
            }
            
        }

        .active-link {
            color: $official-color;
            font-weight: bold;
            
            @include tablet {
                border-bottom-color: $official-color;
            }
        }
    }

    &-filters {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include tablet {
            align-items: center;
            flex-direction: row; 
        }
    }

    &-title {
        margin: 0;
        padding: 15px 10px 5px;
    }

    &-search {
        padding: 5px 10px;

        @include tablet {
            padding: 8px;
        }
    }

    &-list {
        padding: 10px;

        &--count,
        &--order {
            @include font-size(14);
            padding: 5px 10px;

            span {
                font-weight: bold;
            }
        }

        &--order {
            text-align: right;
        }

        &--empty {
            text-align: center;
        }
    }

    &-pagination {
        padding: 25px;
        text-align: center;
        width: 100%;

        span {
            border: 1px solid #eee;
            border-radius: 30px;
            cursor: pointer;
            display: inline-block;
            margin: 0 10px;
            padding: 15px 40px;

            &:hover {
                border-color: #222;
                background-color: #eee;
            }
        }
    }
}

.sports__card {
    display: flex;
    align-items: center;
    position: relative;
    min-height: $card-height-join;

    &-type,
    &-type-expire {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 30%;
        height: 100%;
        color: #fff;

        .sport-icon { 
            filter: brightness(0) invert(1);                  
            width: 70px;
            height: 70px;
            margin-bottom: 10px;
        }
    }

    &-type {
        background-color: $official-color;
        text-align: center;
    }

    &-type-expire {
        background-color: $grey600;
    }

    &-info {
        position: relative;
        left: 30%;
        width: 70%;
    }
    
    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            @extend %text-overflow;
            margin: 0;
            padding: 10px;
            text-align: left;
        }
    }

    &-content {
        padding: 5px 10px;        
    }

    &-actions {   
        display: flex;             
        padding: 10px;
        text-align: right; 
        flex-direction: column;       
        align-items: flex-end;
        
        @include tablet {
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
    
        }
    }

    &-expire {
        padding: 5px;
        color: $grey600;
        text-align: right;
    }
}

.sports__placeholder {
    position: relative;
    height: $card-height;
    background-color: #fff;

    &-type {        
        position: absolute;
        width: 30%;
        height: 100%;
        background-color: $grey400;
    }   

    &-info {
        position: absolute;
        left: 35%;
        background-color: $grey400;
        height: 2rem;
        margin: .5rem;
        width: 60%;
    }
    

    &-action {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 5rem;
        margin: .5rem;
        background-color: $grey400;
        height: 2rem;
    }

    .animated {
        @extend %animated;
    }
}




  