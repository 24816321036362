.login-providers {
    .divider {
        margin: 20px 0;       
    }

    &__providers {
        padding: 10px 0;

        button {
            margin: 7px 0;
        }

        .login-google {
            background-color: #ea4335;

            &:hover {
                background-color: #ea4335;
            }
        }

        .login-facebook {
            background-color: #3C5A99;

            &:hover {
                background-color: #3C5A99;
            }
        }
    }

    &__terms {
        display: flex;        
        align-items: center;
        @include font-size(12);
        line-height: 1.1rem;
        padding: 10px 0;
        text-align: left;     
        
        a {
            color: $official-color;
            border-bottom: 1px solid $official-color;            
        }
    }
}