// register
.register {
    padding: 15px 10px;
    text-align: center;
    min-height: $page-content-height;

    @include tablet {
        padding: 60px 0;
    }

        // register form
    .register__form { 
        max-width: $container-width;    
        margin: 0 auto;

        &-title {
            margin: 0;
            padding-bottom: 10px;
            letter-spacing: 1px;
            
            @include tablet {
                padding-bottom: 30px;
            }
        }

        &--actions {
            padding: 10px 0;
            text-align: left;

            button {
                margin-right: 15px;
            }
        }

        &--divider {
            margin: 15px 0;       
        }

        &--agreement {
            display: flex;        
            align-items: center;
            @include font-size(12);
            padding: 10px 0;
            text-align: left;           

            &-text {
                line-height: 1.4rem;
                padding-left: 5px;

                a {
                    color: $official-color;
                    border-bottom: 1px solid $official-color;            
                }
            }

            @include tablet {
                @include font-size(14);
            }
        }

        &--year {
            @include font-size(14);
            text-align: left;
            padding: 5px 0;

            &-text {
                padding-left: 5px;
            }
        }

        &-error {
            color: $error;
        }

        &--item {
            padding: 5px;

            @include tablet {
                padding: 10px 0;
            }
        }

        @include tablet {
            box-shadow: 0 0 5px #ddd;
            padding: 50px;
        }
    }
}
