.search__address {
    position: relative;

    #search__sport-location {
        text-overflow: ellipsis;
    }

    &-list {
        position: absolute;
        top: 60px;
        z-index: 100;
        max-height: 200px;
        overflow: auto;
        width: 100%;
    }

}