

/*
* sports
*/
.sports {

    &__header {
        padding: 10px 10px 5px;
    
        &-title {
            margin: 0;
            padding: 5px 0;
        }
    }
    
    &__content {
        @include tablet {
            height: $content-height;   
            overflow-x: hidden;
        }
    }

    &__list {
        padding: 10px;

        &--order,
        &--total,
        &--event-tot,
        &--event-ord {
            @include font-size(14);
            padding: 5px 10px;

            span {
                font-weight: bold;
            }
        }

        &--order {
            text-align: right;
        }

        &--event-tot {
            text-align: left;
        }

        &--event-ord {
            text-align: right;
        }

        &--total {
            text-align: left;
        }

        &--empty {
            text-align: center;
        }
    }
}

// sports card
.sports__card {
    h3 {
        white-space: normal;
    }

    &-dash {
        color: #000;
        padding: 0 5px;
    }

    &-header-link {
        color: $secondary-color;
        display: block;
        padding-bottom: 4px;
    }

    &-header-text {
        border-bottom: 1px solid $secondary-color;
    }

    &--cancel,
    &--postpone {
        color: $error;
    }
}


/*
* header and searchbar
*/
.sports__match-header,
.sports__event-header {
    padding: 0 10px;
    
    &-title {
        margin: 0;
        padding: 5px 0;
    }

    &-inputs {
        text-align: left;
    }

    &-filters {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include tablet {
            padding-bottom: 5px;
        }
    }
}

.search__sport-chips {
    display: flex;
    flex-wrap: wrap;

    .search__sport-chip {
        margin: 5px;
    }
}


/*
* MAP
*/
.official__map,
.sport__map,
.centrums__map,
.simple__map {
    max-height: 180px;
    height: calc(100vh - 67px);

    @include tablet {
        max-height: 100%;
    }

    .leaflet-container {
        height: 100%;
    }    
}

.map-placeholder {
    max-height: 180px;
    height: calc(100vh - 67px);
    @extend %animated;
    

    @include tablet {
        max-height: 100%;
    }
}

//chips

.chips__content {
    display: flex;
    overflow-x: auto;
    
    @include tablet {  
        overflow: hidden;
        
        &:hover {
            overflow: auto;
        }
    }
}

.chips__item {
    flex: 0 0 auto;
    margin-right: 5px;
}

/*
* detail page
*/

.sports__detail {
    padding: 5px 5px 15px;

    @include tablet {
        padding: 10px;
        overflow: auto;
        height: $content-height;
    }

    &-title {
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        @include tablet {
            padding-bottom: 15px;
        }
    }

    &-header {
        @include font-size(23);
        margin: 0;
        padding-left: 5px;

        @include tablet {
            @include font-size(28);
        }
    }

    &-header-link {
        color: $secondary-color;
        border-bottom: 1px solid $secondary-color;
    }

    &-dash {
        padding: 0 5px;
    }

    &-expire {
        @include font-size(18);
        padding-bottom: 20px;
        color: $grey600;
    }

    &-icon {
        @include font-size(16);
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-bottom: 10px;     
        
        @include tablet {
            @include font-size(17);
        }
    }

    &-route {
        padding: 10px 0;
    }

    .icon-text {
        padding-left: 10px;
    }

    &--link {
        color: $secondary-color;

        &:hover {
            text-decoration: underline;
        }
    }

    &-extend {
        padding-top: 15px;
    }

    &-ext {
        @include font-size(16);
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        @include tablet {
            @include font-size(17);
        }

        .ext-text {
            color: $grey700;
            padding-right: 5px;
        }
    }

    &--cancel,
    &--postpone {
        color: $error;
    }

    &-result {
        padding-bottom: 15px;
    }

    &-result-text {
        color: $grey600;
    }

    &-result-val {
        font-weight: bold;
        padding-left: 5px;
    }
}

.detail__placeholders {
    height: 100%;

    .first {
        height: 3rem;
        width: 90%;
    }

    .second {
        height: 1.5rem;
        width: 60%;
    }

    .third {
        height: 1.5rem;
        width: 40%;
    }

    .fourth {
        height: 1.5rem;
        width: 50%;
    }

    .animated {        
        @extend %animated;
        border-radius: 8px;
        margin: 10px;
    }
}