.discusion {
    padding: 5px 0 10px;

    &__title {
        margin: 0;
        padding: 10px 0;
    }

    &__submit {
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }

    &__link-name {
        color: $official-color;

        &:hover {
            text-decoration: underline;
        }
    }

    &__message {
        display: block;
        color: #000;
    }

    &__edited {
        @include font-size(12);
    }

    &__item-text {
        padding-left: 10px;
    }

    &__edit {
        display: flex;
        flex-direction: column;
        padding: 5px;

        @include tablet {
            padding: 30px;
            min-width: 500px;
        }
    }

    &__edit-submit {
        padding-top: 10px;

        .delete__coment-dialog {
            background-color: $error !important;
            color: #fff;
        }
    }

    .delete__coment {
        color: $error;        
    }

    

    &__delete {
        padding: 10px;
    }

    &__delete-text {
        @include font-size(18);
        font-weight: bold;
        padding: 20px 0;
    }

    &__placeholders {
        height: 100%;
    
        .second {
            height: 2rem;
            width: 45%;
        }
    
        .third {
            height: 2rem;
            width: 60%;
        }
    
        .fourth {
            height: 2rem;
            width: 55%;
        }
    
        .animated {        
            @extend %animated;
            border-radius: 8px;
            margin: 10px;
        }
    }
}