.offline {
    background-color: $grey200;
    height: calc(100vh - #{$appbar-height});
    text-align: center;

    &__image {
        @include font-size(200);        
    }

    &__title {
        @include font-size(20);
        font-weight: bold;
        padding: 15px 5px;
    }

    &__subtitle {
        padding: 5px 10px;
        max-width: 400px;
        margin: 0 auto;
    }
}