

.loading-wrapper {
    height: 100vh;
    display: flex;
    background-color: $official-color;    

    .loading {
        height: 150px;
        width: 150px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3{
            @include font-size(23);
            color: #fff;
            margin: 0;
            padding-top: 5px;
            font-family: 'Chewy', cursive;
            letter-spacing: 1px;
        }

        &__logo {
            width: 75px;
        }
    
        &:after {
            content: "";
            display: block;
            border: 5px solid;    
            width: 155px;
            height: 155px;
            border-radius: 50%;
            border-color: $secondary-color transparent transparent;
            animation: spin 2s infinite;
            position: absolute;            
            margin: auto;
        }

        &::before {
            content: "";
            display: block;
            border: 5px solid;    
            width: 180px;
            height: 180px;
            border-radius: 50%;
            border-color: $secondary-color transparent transparent;
            animation: spin2 2s infinite;
            position: absolute;            
            margin: auto;
        }

        
    }
}

.waiting-wrapper { 
    padding: 40px;    

    .loading {
        height: 50px;
        width: 50px;
        margin: auto;               
    
        &:after {
            content: "";
            display: block;
            border: 5px solid;    
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border-color: #0ba4f5 transparent transparent;
            animation: spin 2s infinite;
            position: absolute;            
            margin: auto;
        }
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
        filter:hue-rotate(360deg);
    }
}

@keyframes spin2 {
    100% {
        transform: rotate(-360deg);
        filter:hue-rotate(360deg);
    }
}

// Wave A

#wave {
	position: relative;	
	width: 50px;
    margin-left: 5px;
    
	.dot {
		display:inline-block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		margin-right: 4px;
		background: $grey600;
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
    0%, 60%, 100% {
        transform: initial;
    }    
    30% {
        transform: translateY(-15px);
    }
}