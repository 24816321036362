.not-found,
.server-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: $page-content-height;   

    &__wrapper {
        text-align: center;

        @include tablet {
            box-shadow: 0 0 5px #ddd;
            padding: 50px;
        }
    }

    &__ups {
        color: $official-color;
        @include font-size(90);        

        @include tablet {
            @include font-size(130);
        }
    }

    &__info {
        color: $official-color;
        padding: 10px 0;
        @include font-size(25);

        span {
            @include font-size(35);

        }
    }
}