.centrums {
    &__content {
        @include tablet {
            height: $content-height;   
            overflow-x: hidden;
        }
    }

    &__list {
        padding: 10px;
 
        &--order,
        &--total {
            @include font-size(14);
            padding: 5px 10px;

            span {
                font-weight: bold;
            }
        }

        &--order {
            text-align: right;
        }

        &--total {
            text-align: left;
        }

        &--empty {
            text-align: center;
        }
    }
}

/*
* header and searchbar
*/
.centrums__header {
    padding: 10px;

    &-title-filter {
        @include tablet {
            display: flex;
            justify-content: space-between;
        }
    }
    
    &-title {
        margin: 0;
        padding: 5px 0;
    }

    &-inputs {
        text-align: left;
    }

    &-filters {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include tablet {
            padding-bottom: 5px;
        }
    }
}

/*
* centrum detail page
*/

.centrums__detail {
    padding: 5px 5px 15px;

    @include tablet {
        padding: 10px;
        overflow: auto;
        height: $content-height;
    }

    &-title {
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        @include tablet {
            padding-bottom: 15px;
        }

        h1 {
            margin: 0;
            padding-left: 5px;
        }
    }

    &-icon {
        @include font-size(16);
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-bottom: 10px;     
        
        @include tablet {
            @include font-size(17);
        }
    }

    &-information {
        @include font-size(15);
        padding: 5px 0;
        color: $grey700;

        @include tablet {
            @include font-size(16);
        }
    }

    &-firm {
        padding: 10px 0;
        @include font-size(15);
    }

    &-link {
        color: $secondary-color;

        &:hover {
            text-decoration: underline;
        }
    }

    &-route {
        padding: 10px 0;
    }

    .icon-text {
        padding-left: 10px;
    }

    .icon-link {
        padding-left: 10px;
        color: $secondary-color;

        &:hover {
            text-decoration: underline;
            text-decoration-color: $secondary-color;
        }
    }

    &-extend {
        padding-top: 15px;
    }

    &-ext {
        @include font-size(16);
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        @include tablet {
            @include font-size(17);
        }

        .ext-text {
            color: $grey700;
            padding-right: 5px;
        }
    }
}
