.contact {
    padding: 15px 10px;
    text-align: center;
    min-height: $page-content-height;
    max-width: $container-width;    
    margin: 0 auto;

    @include tablet {
        padding: 50px;
    }

    &__title {
        margin: 0;
        padding: 10px 0;
    }

    &__form {
        @include tablet {
            padding-bottom: 15px;
        }
    }

    &__emails {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__mail {
        padding: 20px 10px;
        margin-top: 20px;

        @include tablet {
            padding: 30px 10px;
            width: 50%;
            margin: 30px 10px;
        }

        &-icon {
            @include font-size(80);
        }

        &-title {
            margin: 0;
            padding: 5px;
        }

        &-text {
            padding: 10px;
        }

        &-link {
            color: $official-color;
            font-weight: 500;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.contact-us {
    &__sub {
        color: $grey600;
        padding: 5px;
    }

    &__content {
        padding: 15px 0;
    }

    &__submit {
        padding-top: 15px;

        &-btn {
            width: 100%;

            @include tablet {
                width: auto;
            }
        }
    }

    &__success {
        @include font-size(22);
        font-weight: 500;
        text-align: center;
        padding: 50px 25px;

        @include tablet {
            padding: 80px 50px;
        }        
    }

    &__success-icon {
        @include font-size(80);
    }

    &__success-text {
        padding: 5px;
    }
}