.my__events {
    padding-left: 15px;

    &-title {
        color: $secondary-color;
        margin: 0;
        padding: 10px 5px 5px;
    }

    .events-create {
        margin: 10px 0 20px;
    }
}

.friend__events,
.sport__events {
    
    @include tablet {
        padding: 10px 0;
    } 

    &-item {
        padding: 10px 0 5px;
        text-align: center;
        
        @include tablet {
            padding: 10px 10px 10px 0;
        }
    }

    .events__card {
        display: flex;
        align-items: center;
        position: relative;

        &-type,
        &-type-expire {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 30%;
            height: 100%;
            color: #fff;            

            .sport-icon { 
                filter: brightness(0) invert(1);                  
                width: 70px;
                height: 70px;
                margin-bottom: 10px;
            }
        }

        &-type {
            background-color: $official-color;
            text-align: center;
        }

        &-type-expire {
            background-color: $grey600;
        }

        &-info {
            position: relative;
            left: 30%;
            width: 70%;
        }
        
        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                @extend %text-overflow;
                margin: 0;
                padding: 10px;
                text-align: left;
            }
        }

        &-detail {
            @include font-size(12);

            &:hover {
                background-color: transparent;
                text-decoration: underline;
            }

            @include tablet {
                @include font-size(14);
            }
        }

        &-content {
            padding: 5px 10px;            
        }

        &-actions {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            text-align: right;

            .events-update {
                background-color: #ffc400 !important;
                color: #222;
            }

            .events-delete {
                background-color: $error !important;
                color: #fff;
            }            
        }

        &-expire {
            padding: 10px;
            color: $grey600;
            text-align: right;
        }
    }

    &-empty {        
        text-align: center;

        @include tablet {
            text-align: left;
        }
    }    
}

.joined__events {

    &-title {
        color: $secondary-color;
        margin: 0;
        padding: 15px 10px;
        text-align: left;
    }

    &-item {
        padding: 10px;
        text-align: center;
        
        .events__card {
            display: flex;
            align-items: center;
            position: relative;

            &-type,
            &-type-expire {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 30%;
                height: 100%;
                color: #fff;

                .sport-icon { 
                    filter: brightness(0) invert(1);                  
                    width: 70px;
                    height: 70px;
                    margin-bottom: 10px;
                }
            }

            &-type {
                background-color: $official-color;
                text-align: center;
            }
    
            &-type-expire {
                background-color: $grey600;
            }
    
            &-info {
                position: relative;
                left: 30%;
                width: 70%;
            }
            
            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    @extend %text-overflow;
                    margin: 0;
                    padding: 10px;
                    text-align: left;
                }
            }

            &-detail {
                @include font-size(12);

                &:hover {
                    background-color: transparent;
                    text-decoration: underline;
                }

                @include tablet {
                    @include font-size(14);
                }
            }

            &-content {
                padding: 5px 10px;                
            }

            &-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                text-align: right;

                .card-status {
                    padding: 15px 0;
                    text-align: left;

                    &-PENDING,
                    &-INVITED {
                        color: $secondary-color;
                    }

                    &-ACCEPTED {
                        color: $official-color;
                    }

                    &--info {
                        display: flex;
                        align-items: center;
                    }
                }

                .events-delete {
                    background-color: $error !important;
                    color: #fff;
                    margin-left: 15px;
                }
            }

            &-expire {
                padding: 10px;
                color: $grey600;
                text-align: right;
            }
        }        
    }

    &-empty {        
        text-align: center;

        @include tablet {
            text-align: left;
        }
    }

    &-another {
        padding-bottom: 10px;

        h3 {
            display: inline-block;
            padding-right: 15px;
        }
    }
}

// EVENT DETAIL
.event__detail {
    padding: 5px 10px 15px;

    @include tablet {
        padding: 10px;
        overflow: auto;
        height: $content-height;
    }

    &-title {
        display: flex;
        align-items: center;
        flex-direction: row;
        word-break: break-all;

        h1 {
            margin: 0;
            padding-bottom: 5px;
            word-break: keep-all;
        }
    }

    &-photo {        

        @include tablet {
            padding: 10px 0;
        }

        img {
            max-height: 400px;
            max-width: 100%;
            width: 100%;
        }
    }

    &-ic-text{
        display: flex;
        align-items: center; 
        padding: 5px 0;   
        overflow: hidden;
        @include font-size(14); 

        @include tablet {
            @include font-size(16)
        }
    }
    
    &-buttons {        
        padding-bottom: 15px;

        @include tablet {
            padding-bottom: 0;
        }

        .join-message {
            display: flex;
            align-items: center;
            color: $official-color;
            font-weight: 500;

            .leave-button {
                background-color: $error !important;
                color: #fff;
                margin-left: 15px;
            }
        }

        .pending-message {
            display: flex;
            align-items: center;
            color: $secondary-color;
            font-weight: 500;

            span {
                padding-left: 5px;
            }
        }
        
        .invited-message {
            display: flex;
            
            button {
                margin-right: 10px;
            }
        }

        @include tablet {
            padding: 5px 0;
        }
    }

    &-expire {
        padding: 5px;
        color: $grey600;
    }

    .button-placeholder {
        background: $grey200;
        border-radius: 4px;
        height: 25px;
        width: 100px;
    }

    &-ac {
        @include font-size(12);
        color: $grey500;
        padding-top: 0;

        @include tablet {
            @include font-size(14);
            padding-bottom: 15px;
        }
    }

    &-txt {
        padding-left: 5px;

        @include tablet {
            font-weight: 500;
        }
    }

    &-link {
        color: $secondary-color;
        padding-left: 5px;
        
        &:hover {
            text-decoration: underline;
        }
    }

    &-address {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .show-map {
            @include font-size(10);
            padding: 0;
        }
    }

    &-middle {
        padding: 15px 0;

        @include tablet {
            padding: 5px 0;
        }
    }

    &-owner {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $grey200;
        border-bottom: 1px solid $grey200;
        padding: 5px 0;

        @include tablet {
            border: none;
            padding: 15px 0;
        }

        .owner-wrap {
            display: flex;
            align-items: center;
        }

        .owner-title {
            display: block;
            @include font-size(12);
            color: $grey400;
            margin: 0;
            padding-bottom: 5px;

            @include tablet {
                @include font-size(15);
                color: $grey500;
                font-weight: 500;
                padding-bottom: 10px;
            }
        }

        .owner-name {
            padding-left: 5px;
            color: $official-color;

            @include tablet {
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: $official-color;
                }
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        padding-top: 15px;        

        &-title {
            @include font-size(14);
            color: $grey500;
            padding-bottom: 5px;

            @include tablet {
                @include font-size(15);
                font-weight: 500;
                padding-bottom: 10px;
            }
        }

        @include tablet {
            padding: 10px 0;
            @include font-size(18);
        }
    }

    &-route {
        padding: 10px 0 15px;
    }

    &-participants {
        padding: 10px 0;

        @include tablet {
            padding: 15px 0;
        }

        .participant {
            @include font-size(14);
            display: flex;
            align-items: center;
            padding: 10px 5px;
            border-bottom: 1px solid $grey200;

            &:first-child {
                padding-top: 0; 
            }

            &:last-child {
                border-bottom: none;
            }
            
            a {
                color: $secondary-color;
                padding-left: 5px;

                @include tablet {
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $official-color;
                    }
                }
            }
        }
    } 

    &-private {
        text-align: center;

        &-lock {
            color: $error;
        }

        &-title,
        &-sub {
            margin: 0;
            padding: 15px 0;
        }

        &-owner {
            color: $official-color;
            padding: 10px 5px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

// public events
.events__public {  
    margin-top: -10px;

    &-item {
        padding: 5px;

        @include tablet {
            padding: 10px;
        }

        .events__card {
            display: flex;
            align-items: center;
            position: relative;
            min-height: $card-height;

            &-type,
            &-type-expire {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 30%;
                height: 100%;
                color: #fff;

                .sport-icon { 
                    filter: brightness(0) invert(1);                  
                    width: 70px;
                    height: 70px;
                    margin-bottom: 10px;
                }
            }

            &-type {
                background-color: $official-color;
                text-align: center;
            }
    
            &-type-expire {
                background-color: $grey600;
            }
    
            &-info {
                padding-right: 5px;
                position: relative;
                left: 30%;
                width: 70%;
            }
            
            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    @extend %text-overflow;
                    margin: 0;
                    padding: 10px;
                    text-align: left;
                }
            }

            &-content {
                padding: 5px 10px;                
            }

            &-actions {                
                padding: 10px;
                text-align: right;  

                .event-joined {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: $official-color;

                    .leave-button {
                        background-color: $error !important;
                        color: #fff;
                        margin-left: 15px;
                    }
                }

                .event-pending {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: $secondary-color;
                }

                .same-user {
                    color: $secondary-color;
                }
            }

            &-btns {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
            }

            &-expire {
                padding: 5px;
                color: $grey600;
                text-align: right;
            }

            .button-placeholder {
                background: $grey200;
                border-radius: 4px;
                height: 33px;
                float: right;
                width: 100px;
            }
        }
    }

    &-empty {
        margin: 0 auto;
        text-align: center;
    }
}

.events__dialog {
    &-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
    }

    &-content {
        padding: 10px;

        @include tablet {
            padding: 10px 25px 25px;
        }
    }

    &-text {
        @include font-size(14);
        color: $grey500;
        padding: 10px 0;

        @include tablet {
            @include font-size(16);
        }
    }

    .contact-info {
        display: flex;
        align-items: center;

        &-check {
            padding: 0 10px 0 0;
        }
    }

    &-actions {
        padding: 5px 10px;

        @include tablet {
            padding: 0 25px 10px 25px;
        }
    }
}

.delete__dialog {
    &-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
    }

    &-subtitle {
        margin: 0;
        padding: 10px 10px 5px;

        @include tablet {
            padding-left: 25px;
        }
    }

    &-content {
        padding: 10px;

        @include tablet {
            padding: 25px;
        }
    }

    .delete-info {
        @extend %text-overflow;
        @include font-size(13);        
        padding: 5px 0;

        @include tablet {
            @include font-size(15);
        }

        &-text {
            color: $grey500;
            padding-right: 5px;
        }

        img {
            max-height: 200px;
        }
    }    

    &-actions {
        padding: 5px 10px;

        @include tablet {
            padding: 0 25px 10px 25px;
        }

        .delete-button {
            background-color: $error !important;
            color: #fff;
            margin-left: 15px;
        }
    }
}