.privacy {
    max-width: $container-width;    
    margin: 0 auto;
    padding: 15px;
    text-align: left;
    min-height: $page-content-height;

    @include tablet {
        padding: 60px 0;
    }

    &__title {
        margin: 0;
        text-align: center;
    }

    .title {
        font-weight: bold;
        padding: 15px 0 5px;
    }

    a {
        color: $secondary-color;

        &:hover {
            text-decoration: underline;
        }
    }
}