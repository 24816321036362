// login 
.login {
    padding: 15px 10px;
    text-align: center;
    min-height: $page-content-height;

    @include tablet {
        padding: 60px 0;
    }

    .login__form {
        max-width: $container-width;    
        margin: 0 auto;

        @include tablet {
            box-shadow: 0 0 5px #ddd;
            padding: 50px;
        }

        &--title {
            margin: 0;
            padding: 10px 20px;
            letter-spacing: 1px;
            
            @include tablet {
                padding-bottom: 40px;
            }
        }

        &--item {
            padding: 5px;           
        }

        &--actions {
            padding: 10px 0;
            text-align: left;

            button {
                margin: 15px 0;
            }
        }

        &--forget {
            @include font-size(12);            
            color: #9E9E9E;
            display: inline;
            text-align: right;
            
            &:hover {
                color: $secondary-color;
                text-decoration: underline;
            }
        }
    }
}