
/*
* header default style
*/
.header {
    background-color: $official-color;
    border-bottom: 3px solid darken($official-color, 20%);
    color: white;
    font-weight: 500;  


/*
* Logo
*/
.header__logo {    
    a {
        color: #fff;  
        display: flex;   
        align-items: center;   

        &:hover {            
            text-decoration: none;
        }
    }
    
    .header__logo-heading {
        font-family: 'Chewy', cursive;
        margin: 0;       
    }

    .header__logo-image {
        width: 55px;
        height: 55px;

        @include tablet {
            width: 60px;
            height: 60px;
        }
    }
}

/*
* header navigation
*/
.header__navigation {
    display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    
    a {
        color: #fff;

        &:hover {
            color: #011f43;
            text-decoration: none;
        }
    }

    .active-link {
        color: #011f43;
    }
}

.header__mobile {
    color: #fff;

    &-button {
        padding-left: 0;
        
        &:hover {
            background-color: transparent;
        }
    }
}




/*
* header user
*/
.header__user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    a {
        color: #fff;

        &:hover {
            color: #001b40;
            text-decoration: none;
        }
    }

    .active-link {
        color: #001b40;
    }

    &-name {
        display: inline-block;
        @extend %text-overflow;       
        font-weight: inherit;
        max-width: 150px;
    }    

    &-icon {
        @include font-size(30);
        color: #fff;
    }

    &-icon-button {
        margin-right: -12px;

        @include tablet {
            margin-right: 0;
        }
    }

    &-button {
        outline: none;  
        margin-right: -12px;
        
        @include tablet {
            margin-right: 0;
        }
    }

    &-search {
        display: inline-block;
        position: relative;

        &--wrap {
            display: flex;
            align-items: center;
        }

        input {  
            background: darken($official-color, 2%);
            border-radius: 2px;
            padding: 5px;
            color: #fff;
        }

        #search-list {            
            background-color: #fff;
            box-shadow: 1px 1px 3px #bdbdbd;
            position: absolute;
            right: 0;
            top: 40px;     
            width: 100%;  
            max-height: 429px;     
            z-index: 1200;
            overflow: auto;
            width: 340px;

            .search-list-item {
                border-bottom: 1px solid #ddd;
                color: $official-color;
                padding: 10px 15px;

                a {
                    @include font-size(14);
                    color: $official-color;
                    line-height: 1;

                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $official-color;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            ul {
                margin: 0;
                padding: 0;
            }
        }
    }

    &-friends {
        display: inline-block;
        position: relative;
    }

    &-notifications {
        display: inline-block;
        position: relative;
    }

    &-bottom-navigation {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000; 
        border-top: .5px solid #000;

        &-item,
        a {           
            color: #757575;
            &:hover,
            &:active {
                color: $official-color;
            }            
        }
    }
}

}

#header__user-menu {
    .header__user-menu-item {
        cursor: pointer;

        &:hover {
            background-color: #eee;
        }
    }
}


.mobile__user-menu {
    .active-mobile-link {
        background-color: #ddd;
        
        & * {
            color: $secondary-color;
        }
    }

    &-item {
        &-logo {
            color: $official-color;
            font-family: 'Chewy', cursive;
            margin: 0;
            padding: 5px 0;
            width: 100%;
            text-align: center;
        }
    }
}

/**
* NOTIFICATION DIALOG
*/

.notification__dialog {
    &-toolbar {
        display: flex;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
    }

    &-search {
        position: relative;
    }
}
