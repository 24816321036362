.user__settings {
    padding: 15px 10px;
    text-align: center;

    @include tablet {
        padding: 60px 0;
        min-height: $page-content-height;
    }

    &-container {
        max-width: 800px;    
        margin: 0 auto;

        @include tablet {
            box-shadow: 0 0 5px #ddd;
            padding: 30px;
        }
    }

    &-title {
        margin: 0;
        padding: 10px 20px;
        
        @include tablet {
            @include font-size(27);
            padding-bottom: 30px;
        }
    }

    &-grid {
        @include tablet {
            padding: 10px;
        }
    }

    .sett__photo {
        img {
            min-height: 180px;
            max-height: 214px;
            width: 180px;
            border-radius: 4px;
        }

        &-button {
            margin: 10px 0;
        }
    }

    .sett__credentials {  

        @include tablet {
            padding-bottom: 10px;
            border-bottom: 1px solid $grey400;
            text-align: left;
        }

        &-title {
            margin: 0;
            padding: 5px 0;
            text-align: left;

            @include tablet {
                padding: 0 0 5px;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 10px;

            @include tablet {
                padding: 5px 0;
            }
        }

        &-edit {
            display: flex;
            align-items: center;
            justify-content: center;

            @include tablet {
                justify-content: left;
            }
        }

        .change-pass {
            margin: 10px 0;
        }
    }    

    .sett__contact {
        padding: 10px;

        @include tablet {
            padding: 0 0 10px;
            border-bottom: 1px solid $grey400;
        }

        &-title {
            margin: 0;
            padding: 5px 0;
            text-align: left;

            @include tablet {
                padding-top: 15px;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0;
        }

        &-phone,
        &-messenger {
            display: flex;
            align-items: center;
            justify-content: left;

            &-name {
                padding-right: 10px;
                font-weight: 500;
            }

            &-value {
                @extend %text-overflow;
            }
        }
    }

    .sett__providers {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;

        @include tablet {
            border-bottom: 1px solid $grey400;
            margin-bottom: 20px;
        }

        &-title {
            margin: 0;
            padding: 5px 0;
            text-align: left;

            @include tablet {                
                padding-top: 15px;
            }
        }

        &-info {
            @include font-size(14);
            color: $grey500;
            text-align: left;
            padding: 5px 10px;

            @include tablet {
                padding: 5px 0;
            }
        }

        .link-but {
            margin: 10px 0;
        }

        .link-google {
            background-color: #ea4335 !important;
            color: #fff;
        }

        .link-facebook {
            background-color: #3C5A99 !important;
            color: #fff;
        }

        &-anim {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
        }
    }

    .sett__delete {
        &-message {
            color: $error;
            padding: 10px;

            @include tablet {
                padding-bottom: 20px;
            }
        }        

        .delete-user {
            background-color: $error !important;
            color: #fff;
            margin-bottom: 5px;

            @include tablet {
                margin-bottom: 20px;
            }
        }        
    }

    .sett__button {
        margin-left: 15px;
    }
}

.delete__dialog {
    &-message {
        color: $error;
        padding: 10px 0;

        @include tablet {
            @include font-size(18);
            padding-bottom: 20px;
        }
    }     

    &-btns {
        display: flex;
        align-items: center;        
    }

    .delete-user {
        background-color: $error !important;
        color: #fff;
    }

    &-anim {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }
}


// photo dialog
.photo__dialog {
    @include tablet {
        padding: 20px;
    }
    &-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
    }

    &-content {
        padding: 20px 10px 10px;
        text-align: center;

        @include tablet {
            padding: 25px;
        }

        img {
            min-height: 180px;
            max-height: 214px;
            width: 180px;
            border-radius: 4px;
            margin-bottom: 15px;
        }        
    }     

    &-actions {
        text-align: center;

        @include tablet {
            padding-bottom: 25px;
        }
    }
}

#photo-loading {
    display: none;
    margin: 5px 0;
}