.ic-text {
    @include font-size(14);
    display: flex;
    align-items: center;

    &-txt {
        @extend %text-overflow;
        padding-left: 10px;                                       
    }

    &-bold {
        font-weight: bold;
    }

    &-link {
        color: $secondary-color;

        &:hover {
            text-decoration: underline;
            text-decoration-color: $secondary-color;
        }
    }

    &-icon {
        @include font-size(20);
        color: $grey400;

        @include tablet {
            @include font-size(22);
        }
    }
}  