
.homepage {

    &__intro {
        background-color: $grey200;
        background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../../public/assets/hp.jpg') no-repeat center center / cover;
        height: calc(100vh - 61px);
        width: 100%;

        &-info {
          color: #fff;          
          height: 100%;
          text-align: center;

          .hp-title {
            @include font-size(32);
            font-family: 'Chewy', cursive;
            margin: 0;
            padding: 80px 5px 20px;
            letter-spacing: 2px;

            @include tablet {
              @include font-size(58);
              padding-top: 80px;
              letter-spacing: 3px;
            }
          }

          .hp-subtitle {
            @include font-size(22);
            font-family: 'Chewy', cursive;
            margin: 0;
            padding: 20px 5px 30px;
            letter-spacing: 1px;
            
            @include tablet {
              @include font-size(38);
              padding: 30px 5px;
              letter-spacing: 2px;
            }
          }
        }
    }

    &__bounce {
      display: none;

      @include tablet {
        display: block;
      }
      
      border: 2px solid white;
      border-radius: 50%;
      bottom: 0;
      background: transparent;
      color: white;
      cursor: pointer;
      height: 40px;
      left: 50%;
      margin-left: -20px;
      margin-bottom: 10px;
      position: absolute;  
      width: 40px;

      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;

      svg {
          height: auto;
          width: auto;
      }
  }

    &__content {

        &-info {
          padding: 20px 5px;

          @include tablet {
            padding: 60px 20px;
            max-width: 1080px;
            margin: 0 auto;
          }

          @include desktop {
            display: flex;
            align-items: center;
            padding: 60px 10px;
            max-width: 1200px;
          }

          .action-photo {
            background: url('../../public/assets/join.png');            
          }

          .place-photo {
            background: url('../../public/assets/actions.png');
          }

          .user-photo {
            background: url('../../public/assets/centrums.png');
          }

          .action-photo,
          .place-photo,
          .user-photo {
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 8px 8px 0 0;
            width: 100%;
            height: 150px;
          }

          .text-title {
            margin: 0;
            padding: 10px;
          }

          .text-subtitle {
            margin: 0;
            padding: 20px 5px;
          }
        }

        &-wrapper {
          border: 1px solid $grey300;
          border-radius: 8px;
          padding-bottom: 30px;
          margin-bottom: 20px;
          height: 100%;
          text-align: center;

          @include tablet {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 0;            
          }
        }

        &-missing {       
            display: flex;
            flex-direction: column;
            justify-content: center;  
            text-align: center;
            padding: 10px;

            @include tablet {
              padding: 40px 20px;
            }

            .mis-icons {
              display: flex;
              align-items: center;
              flex-direction: column;
              color: $grey400;
              padding-bottom: 15px;

              &-location {
                @include font-size(90);

                @include tablet {
                  @include font-size(120);
                }
              }

              &-map {
                @include font-size(60);
                margin-top: -15px;

                @include tablet {
                  @include font-size(80);
                  margin-top: -20px;
                }
              }
            }

            .mis-title {
              color: $official-color;
              margin: 0;
              padding: 15px 10px;
            }

            .mis-sub {
              color: $grey500;
              padding-bottom: 10px;

              @include tablet {
                @include font-size(18);
              }
            }

            .mis-btns {
              display: flex;
              align-items: center;
              justify-content: center;

              &-btn {
                margin: 10px;

                @include tablet {
                  margin: 20px 10px;
                }
              }
            }
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-10px);
    }
  }