/*
* LOGOUT COMPONENT
*/

.logout {
    color: red;
    padding: 30px 15px;
    text-align: center;   
    
    .logout__buttons {
        a {
            margin: 10px;
        }
    }
}