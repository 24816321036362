
.footer {
    @include font-size(14);
    color: $grey300;
    padding: 20px 10px;
    background-color: #000;

    @include tablet {
        @include font-size(16);
        padding: 20px 70px;
    }

    &__contact {
        display: flex;
        justify-content: space-between;

        a {
            color: $grey300;
            margin: 6px 0;

            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }

        &-legal {
            display: flex;
            flex-direction: column;
        }
    }

    &__rights {
        padding: 15px 5px;
        text-align: center;

        @include tablet {
            padding: 15px;
        }

        &-text {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.footer__list {
    @include font-size(13);
    border-top: 1px solid $grey300;
    padding: 10px 5px;
    text-align: center;

    &-contact {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .footer-link {
            color: $official-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-icons {        
        .footer-link {
            color: $official-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-rights {
        padding-top: 5px;
    }

    &-text {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}