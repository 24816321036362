.popup__participants,
.popup__friends {
    &-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
    }

    &-placeholder {
        padding-top: 5px;
        padding-left: 13px;

        @include tablet {
            padding-left: 23px;
        }

        .placeholder-wrap {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
        }

        .placeholder-photo {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $grey300;
        }

        .placeholder-text {
            display: inline-block;
            width: 120px;
            height: 25px;
            background-color: $grey300;
            border-radius: 4px;
            margin-left: 10px;
        }
    }
}

.popup__wrapper {
    &-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
    }
}