
.create__event {
    
    .event-form {
        overflow: auto;
        padding: 10px;

        @include tablet {
            padding: 15px 30px;
            height: calc(100vh - 67px);
            overflow: auto;
        }

        &__title-wrapper {
            display: flex;
            align-items: flex-start;
        }

        &--title {
            margin: 0;
            padding: 5px 5px 15px 0;
        }

        &-wrapper {
            padding-bottom: 20px;

            @include tablet {
                padding-bottom: 50px;
            }
        }
    }

    .create-title {
        margin: 0;
        padding: 10px 0;
        text-align: center;

        @include tablet {
            padding: 15px 0;
            text-align: left;
        }
    }

    .event__friends {
        margin: 10px 0;
    }
    
    .event__button {
        margin-top: 10px;
    }

    .event__creating {
        color: $official-color;
        display: flex;
        align-items: center;
        padding: 5px 0;
    }

    #action_photo {
        display: none;
        max-height: 400px;
        max-width: 100%;
        width: 100%;
    }

    .event__photo {
        display: flex;
        flex-direction: column;
        padding: 5px 0;
    }

    #event-photo {
        display: none;
    }

    &-info-dialog {
        &-content {
            div {
                padding: 10px 0;
            }

            .info-bold {
                font-weight: 600;
            }
        }
    }
}

#menu-invite {
    .friend-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

