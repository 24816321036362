@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Chewy&display=swap');

* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;

    a {
        text-decoration: none;
    }
}

#app {
    min-height: 100vh;
    overflow: hidden;    
    clear: both;   
}

.user-login {
    margin-bottom: 56px;

    @include tablet {
        margin-bottom: 0;
    }
}