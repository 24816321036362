.pagination {
    text-align: center;
    padding: 15px 10px;

    @include tablet {
        padding: 30px 10px;

        &-dense {
            padding: 5px;
        }
    }

    &__btns {
        display: flex;
        justify-content: center;
    }

    .pagination__btns--prev {
        margin: 0 10px;
    }

    .pagination__btns--next {
        margin: 0 10px;
    }
}