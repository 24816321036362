.password-reset,
.verification {
    padding: 15px 10px;
    text-align: center;
    min-height: $page-content-height;

    @include tablet {
        padding: 60px 0;
    }

    &__container {
        max-width: $container-width;    
        margin: 0 auto;

        @include tablet {
            box-shadow: 0 0 5px #ddd;
            padding: 50px;
        }
        
        &--title {
            margin: 0;
            padding: 10px 20px;
            letter-spacing: 1px;  
        }

        &--email {
            color: $official-color;
        }

        &--error {
            color: $error;
        }

        button {
            margin: 20px 0;
        }
    }    
}