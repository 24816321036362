.forget-password {
    padding: 15px 10px;
    text-align: center;
    min-height: $page-content-height;

    @include tablet {
        padding: 60px 0;
    }

    &-container {
        max-width: $container-width;    
        margin: 0 auto;

        @include tablet {
            box-shadow: 0 0 5px #ddd;
            padding: 50px;
        }
    }

    &-title {
        margin: 0;
        padding: 10px;
    }

    &-subtitle {
        margin: 0;
        padding: 10px 0 15px 0;

        @include tablet {
            padding-bottom: 40px;
        }
    }

    button {
        margin: 15px 0;

        @include tablet {
            margin: 30px 0;
        }
    }
}