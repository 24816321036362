.friends__list {    
    padding: 15px;
    text-align: center;

    &-wrapper {
        padding-bottom: 10px;
    }

    &-item {
        padding: 10px;
    }

    &-card {
        display: flex;
        align-items: center;
    }

    &-title {
        color: $secondary-color;
        margin: 0;
        padding: 10px;
        text-align: left;
    }

    &-area {
        width: 80px !important;

        @include tablet {
            width: 130px !important;
        }
    }
    
    &-photo {
        border-radius: 4px;
        height: 80px;
        width: 80px;

        @include tablet {
            height: 130px;
            width: 130px;
        }
    }

    &-info {
        width: 100%;
        padding: 10px 5px;        
    }

    &-link {
        color: $official-color;
        word-break: break-word;

        &:hover {
            text-decoration: underline;
        }
    }

    &-empty {
        padding: 10px;

        .empty-title {
            margin: 0;
        }
    }    
}