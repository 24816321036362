.user__account-info {
    padding: 30px 0;
    text-align: left;

    .account__info-photo {
        text-align: right;
        img {
            height: 200px;
            width: 200px;
            border-radius: 50%;
        }
    }

    .account__info-credentials {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }


}


/**
* Users settings info 
*/
.user__account-info-change {

    .account__info-photo {
        img {
            height: 200px;
            width: 200px;
            border-radius: 50%;
        }        
    }   
}

.update-photo-dialog {   

    &-title {       

        h3 {
            flex-grow: 1;
            line-height: 48px;
            margin: 0;
        }
    }

    &-content {
        margin: 0 auto;
        padding: 24px;
        position: relative;

        img {
            border-radius: 50%;
            text-align: center;
            width: 400px;
            height: 400px;
        }
    }
}

