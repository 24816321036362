.c-sportclub-detail {
    padding: 10px;
    
    @include tablet {
        height: $content-height;   
        overflow-x: hidden;
        padding: 15px;
    }

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
    }

    &__title {
        @include font-size(24);
        margin: 0;        

        @include tablet {
            @include font-size(32);
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
    }

    &__bold-text {
        font-weight: bold;
        padding-left: 5px;
    }

    &__link {
        color: $secondary-color;
        padding-left: 10px;

        &:hover {
            text-decoration: underline;
        } 
    }

    &__tabs {
        padding-top: 5px;

        @include tablet {
            padding-top: 15px;
        }
    }
}

.c-club-matches,
.c-club-results {
    &__date {
        @include font-size(12);
        display: flex;
        flex-direction: column;

        @include tablet {
            @include font-size(14);
        }
    }

    &__canceled,
    &__postpone {
        @include font-size(12);
        color: $error;

        @include tablet {
            @include font-size(14);
        }
    }

    &__load-more {
        padding: 10px;
        text-align: center;
    }

    &__no-results {
        padding-top: 10px;
        color: $grey700;
        text-align: center;
    }
}

.c-club-results {
    &__participants {
        @include font-size(13);
        line-height: 1.4;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 0 16px;
    }

    &__result {
        @include font-size(13);
    }

    &__winner {
        color: $official-color;
    }

    &__loser {
        color: $error;
    }
}

.c-club-table {
    &__no-results {
        padding-top: 10px;
        color: $grey700;
        text-align: center;
    }

    &__team {
        @include font-size(13);
        line-height: 1.4;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 0 16px;
    }

    &__points,
    &__rank {
        @include font-size(13);
    }

    &__current-item {
        background-color: $grey300;
    }

    &__current-item &__rank,
    &__current-item &__points,
    &__current-item &__team {
        font-weight: bold;
    }
}

.c-club-table,
.c-club-results,
.c-club-matches {
    min-height: 200px;
}

.c-clubs {
    min-height: $page-content-height;
    padding: 5px 10px;

    @include tablet {
        max-width: 1100px;
        margin: 0 auto;
        padding: 5px 25px;
    }

    &__title {
        @include font-size(24);
        margin: 0;
        padding: 10px 0 15px;
        text-align: center;

        @include tablet {
            @include font-size(32);
        }
    }

    &__img {
        display: none;

        @include tablet {
            display: block;
            text-align: center;
        }        
    }
}

.c-clubs-list {
    padding-bottom: 15px;

    @include tablet {
        padding-bottom: 25px;
    }

    &__count {
        padding: 10px 0;

        @include tablet {
            padding: 15px 0;
        }
    }

    &__count-val {
        font-weight: bold;
    }

    &__card {
        min-height: 160px;

        .sport-icon {
            width: 55px;
            height: 55px;
        }
    }

    &__no-results {
        @include font-size(18);
        line-height: 27px;
        color: $grey700;
        text-align: center;
        padding: 20px;
    }
}