// colors
$official-color: #019964;
$official-color-light: #51cb92;
$secondary-color: #0ba4f5;
$third-color: #011f43;
$error: #f44336;
$border-color: #ddd;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #bdbdbd;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;

//variables
$appbar-height: 67px;

//footer list height
$footerList-heiht: 68px;

// footer height
$footer-height: 174px;

// list content height
$content-height: calc(100vh - (#{$appbar-height} + #{$footerList-heiht}));

// not list content height
$page-content-height: calc(100vh - (#{$appbar-height} + #{$footer-height}));

//container max width
$container-width: 650px;

//profil max width
$profil-width: 1300px;

//card height
$card-height: 214px;

//card heigth join
$card-height-join: 218px;

//media queries
$sm: 800px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

$smEm: 50em;
$mdEm: 60em;

/**
* MIXINS
*/

@mixin font-size($size) {
    font-size: ($size / 16) * 1rem;
}

@mixin tablet {
    @media screen and (min-width: $smEm) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $mdEm) {
        @content;
    }
}

/**
* EXTENDS
*/

%full-height {
    height: calc(100vh - 67px);
}

%text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

%animated {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $grey200;
    background: linear-gradient(to right, $grey200 4%, $grey300 25%, $grey200 36%);
    background-size: 1000px 100%;
}

@keyframes placeHolderShimmer{
  
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}